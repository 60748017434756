// external
import type { AxiosInstance, AxiosResponse } from 'axios';
// internal
import type {
  GetSwipesRequest,
  GetSwipesResponse,
  GetUsedSwipesResponse,
  MakeSwipeRequest,
  SwipeGiftItem,
  SwipePlan,
  SwipePlanPaymentResponse,
} from '@/types';

export default (api: AxiosInstance) => ({
  getUsedSwipes(): Promise<AxiosResponse<GetUsedSwipesResponse>> {
    return api.get<GetUsedSwipesResponse>('/v1/swipes/used/');
  },
  getSwipesList(params: GetSwipesRequest): Promise<AxiosResponse<GetSwipesResponse>> {
    return api.get<GetSwipesResponse>('/v1/swipes/', { params });
  },

  makeSwipe(requestId: string, data: MakeSwipeRequest): Promise<AxiosResponse> {
    return api.post(`/v1/swipes/${requestId}/`, data, { timeout: 10000 });
  },

  getGiftsList(): Promise<AxiosResponse<SwipeGiftItem[]>> {
    return api.get<SwipeGiftItem[]>('/v1/swipes/gifts/');
  },
  getRandomGift(): Promise<AxiosResponse<SwipeGiftItem>> {
    return api.get<SwipeGiftItem>('/v1/swipes/gifts/apply/');
  },

  getSwipesPlans(): Promise<AxiosResponse<SwipePlan[]>> {
    return api.get<SwipePlan[]>('/v1/meme_tariff/');
  },
  payForSwipes(planId: number): Promise<AxiosResponse<SwipePlanPaymentResponse>> {
    return api.get<SwipePlanPaymentResponse>(`/v1/meme_tariff/${planId}/`);
  },
});
