// external
import type Cookies from 'js-cookie';

export enum CookiesNames {
  AUTH_TOKEN = 'mm_authToken',
  REFRESH_TOKEN = 'mm_refreshToken',
  STORE_IN_DB = 'mm_storeInDb',
  HIDE_TOOLTIP_CUSTOM_MEME = 'mm_hideTooltipCustomMeme',
  SKIP_SWIPES_INTRO = 'mm_skipSwipesIntro',
  SKIP_ADD_MEME_INTRO = 'mm_skipAddMemeIntro',
  SWIPES_ADDED_IN_LAST_5_MINUTES = 'mm_swipesAddedInLast5Minutes',
}

export const cookiesSetOptions: Cookies.CookieAttributes = {
  path: '/',
  expires: 30,
  sameSite: 'strict',
};
export const cookiesSetOptionsYear: Cookies.CookieAttributes = {
  ...cookiesSetOptions,
  expires: 365,
};

export default {
  CookiesNames,
  cookiesSetOptions,
  cookiesSetOptionsYear,
};
