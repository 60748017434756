// external
import type { AxiosInstance, AxiosResponse } from 'axios';
// internal
import type {
  CreateMemeRequest,
  CreateMemeResponse,
  GenerateMemeRequest,
  GenerateMemeResponse,
  GenerateStatusResponse,
  EditMemeRequest,
  EditMemeResponse,
  RateGenerationRequest,
  GetMemeVariantsResponse,
  SavedMeme,
  MemeVariant,
  SaveCustomMemeTemplateRequest,
} from '@/types';

export default (api: AxiosInstance) => ({
  createMeme(data: CreateMemeRequest): Promise<AxiosResponse<CreateMemeResponse>> {
    return api.post<CreateMemeResponse>('/v1/memes/', data);
  },
  generateMeme(data: GenerateMemeRequest): Promise<AxiosResponse<GenerateMemeResponse>> {
    return api.post<GenerateMemeResponse>('/v1/memes/generations/', data);
  },
  checkGenerationStatus(requestId: string): Promise<AxiosResponse<GenerateStatusResponse>> {
    return api.get<GenerateStatusResponse>(`/v1/memes/generations/${requestId}/status/`);
  },

  editMeme(requestId: string, data: EditMemeRequest): Promise<AxiosResponse<EditMemeResponse>> {
    return api.patch<EditMemeResponse>(`/v1/memes/${requestId}/`, data);
  },

  getMemeVariants(): Promise<AxiosResponse<GetMemeVariantsResponse>> {
    return api.get<GetMemeVariantsResponse>('/v1/memes/generations/templates/');
  },

  saveCustomMemeTemplate(data: SaveCustomMemeTemplateRequest): Promise<AxiosResponse<MemeVariant>> {
    return api.post<MemeVariant>('/v1/memes/generations/templates/', data);
  },

  rateGeneration(requestId: string, params: RateGenerationRequest): Promise<AxiosResponse> {
    return api.post(`/v1/memes/generations/${requestId}/rate/`, null, { params });
  },

  getMemesHistory(): Promise<AxiosResponse<SavedMeme[]>> {
    return api.get<SavedMeme[]>('/v1/memes/');
  },
});
