// external
import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
// internal
import router from '@/router';

export const sentryPlugin = {
  install: (app: App) => {
    if (import.meta.env.VITE_IS_LOCAL_DEV) {
      return;
    }

    Sentry.init({
      app,
      // dsn: 'https://91ebf3963d728eb6d5b73a2fcfd83c06@o4505596202188800.ingest.us.sentry.io/4508041863233541', // main
      dsn: 'https://ec0272da52461537fa80e4a4c28628fb@o4508490270310400.ingest.de.sentry.io/4508490274111568', // tmp
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/api\.mememe\.im\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  },
};

export default sentryPlugin;
