// external
import type { AxiosInstance, AxiosResponse } from 'axios';
// internal
import type {
  FeedListRequest,
  FeedListResponse,
  FeedItemVoted,
  MakeFeedVoteRequest,
} from '@/types';

export default (api: AxiosInstance) => ({
  getFeedList(params: FeedListRequest): Promise<AxiosResponse<FeedListResponse>> {
    return api.get<FeedListResponse>('/v1/feed/', { params });
  },
  getFeedVoted(): Promise<AxiosResponse<FeedItemVoted[]>> {
    return api.get<FeedItemVoted[]>('/v1/feed/voted/');
  },
  voteForFeedItem(requestId: string, data: MakeFeedVoteRequest): Promise<AxiosResponse> {
    return api.post(`/v1/feed/${requestId}/`, data);
  },
});
